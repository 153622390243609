<template>
  <NavBar />

  <router-view />

  <FooterItem />
</template>

<script>
import NavBar from "./components/Nav.vue";
import FooterItem from "./components/Footer.vue";

export default {
  components: {
    NavBar,
    FooterItem,
  },
};
</script>

<style>
body {
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: var(--background-colour);
}
h1 {
  font-size: 2.5rem;
  color: var(--text-colour);
  margin: 1rem 0;
  font-weight: 500;
}
h2 {
  font-size: 2rem;
  color: var(--text-colour);
  margin: 0;
}
h3 {
  font-size: 1.75rem;
  color: var(--text-colour);
  font-weight: 400;
  margin: 0.5rem 0;
}
h4 {
  font-size: 1.5rem;
  color: var(--action-colour);
  margin: 0.5rem 0;
}
h5 {
  font-size: 1rem;
  color: var(--text-colour);
  margin: 0;
  font-weight: 400;
}
p {
  color: var(--tertiary-colour);
  font-size: 1.3rem;
  margin: 1rem 0;
  font-weight: 300;
}

.btn {
  width: 15rem;
  height: 2.5rem;
  margin: 0.25rem;
  border-radius: 10px;
  border: none;
  box-shadow: var(--box-shadow);
  transition: all 250ms ease-in-out;
  font-weight: 700;
}
.btn:hover {
  cursor: pointer;
}
.btn:active {
  scale: 0.95;
}
</style>
