<template>
  <div class="content column">
    <section class="standout background-image">
      <div class="overlay left column">
        <article class="column pad right third">
          <h1>Welcome to Beamy</h1>
          <h2>Revolutionizing Your Point of Sale Experience</h2>
          <p>
            Transform the way you do business with Beamy, the cutting-edge Point
            of Sale software designed to streamline your operations and boost
            your bottom line. Whether you're a small boutique or a bustling
            café, Beamy offers a seamless, user-friendly solution tailored to
            meet your unique needs.
          </p>
          <a href="https://bridge.beamy.nz/signup" target="_blank" aria-description="Link to sign up page"
            ><button class="btn">Start free trail</button></a
          >
        </article>
      </div>
    </section>

    <section class="banner-item">
      <h2>Why Beamy</h2>
      <div class="banner">
        <Vue3Marquee id="marquee-slider" :speed="6000" :width="100">
          <div
            v-for="(point, idx) in sellingPoints"
            :key="idx"
            class="scroll"
            id="scroll-one"
          >
            <div class="item">
              <h3>{{ point.title }}</h3>
              <p>{{ point.blurb }}</p>
            </div>
          </div>
        </Vue3Marquee>
      </div>
    </section>

    <section class="central">
      <div class="row left box">
        <div class="quarter pad right">
          <img
            src="../../public/pos-home-screen.jpg"
            class="small-img"
            alt=""
          />
        </div>
        <div class="line-break"></div>
        <div class="pad half">
          <h3>Point of sale</h3>
          <p>
            Our POS is designed to make hospitality operations easier. With a
            user-friendly interface, you can easily manage taking orders and
            processing payments.
          </p>
        </div>
      </div>

      <div class="row-reverse box">
        <div class="quarter pad left">
          <img src="../../public/kds-screen.png" class="small-img" alt="" />
        </div>
        <div class="line-break"></div>
        <div class="pad right half">
          <h3>Kitchen Display</h3>
          <p>
            Our modern KDS keeps your operations up to date by displaying orders
            in real-time. This means you can stay organized and efficient,
            ensuring orders are prepared promptly and accurately. Our KDS can
            also connect to a label printer to optimize takeaway orders.
          </p>
        </div>
      </div>

      <div class="row left box">
        <div class="quarter pad right">
          <img src="../../public/companion.png" class="small-img" alt="" />
        </div>
        <div class="line-break"></div>
        <div class="pad half">
          <h3>Companion</h3>
          <p>
            Stremline all checkout processes with our companion. Designed to
            work with our POS, you can say goodbye to customer checkbacks and
            miscommunication. Increase efficiency and customer satisfaction with
            our companion.
          </p>
        </div>
      </div>

      <div class="row-reverse box">
        <div class="quarter pad left">
          <img src="../../public/reports.png" class="small-img" alt="" />
        </div>
        <div class="line-break"></div>
        <div class="pad right half">
          <h3>Reporting</h3>
          <p>
            Keep track of all that matters with our reporting. Whether you want
            to track your sales, break out trends or customer behaviour, our
            reporting system will provide you with everything you need.
          </p>
        </div>
      </div>

      <div class="row box">
        <div class="quarter pad right">
          <img src="../../public/cogs2.png" class="small-img" alt="" />
        </div>
        <div class="line-break"></div>
        <div class="pad left half">
          <h3>Cost of Goods (COGS)</h3>
          <p>
            Use COGS to gain instights, find big ticket items and ensure you are
            selling at the right price point. Keep track of all your suppliers
            and their products so you know you are getting the best price.
          </p>
        </div>
      </div>

      <div class="row-reverse box">
        <div class="quarter pad left">
          <img src="../../public/test-ordering.jpeg" class="small-img" alt="" />
        </div>
        <div class="line-break"></div>
        <div class="pad right half">
          <h3>Web Store</h3>
          <p>
            Give your customers even more access to your products with our web
            store. With a seamless integration to your POS, you can easily
            manage your online store and keep track of all your sales in one
            place.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Vue3Marquee } from "vue3-marquee";
export default {
  name: "HomeView",
  components: {
    Vue3Marquee,
  },
  data() {
    return {
      currentIndex: 0,
      sellingPoints: [
        {
          title: "Intuitive Design",
          blurb:
            "Keep it simple, our POS is easy to use requiring minimal training for your staff.",
        },
        {
          title: "Comprehensive Features",
          blurb:
            "Manage inventory, track sales, and analyze data with Beamy's solutions.",
        },
        {
          title: "Cloud-Based Convenience",
          blurb: "Monitor sales and inventory in real-time from any device.",
        },
        {
          title: "Reliable Support",
          blurb:
            "Our support team is here for you, ensuring a smooth experience.",
        },
        {
          title: "Affordable Pricing",
          blurb:
            "Flexible plans for businesses of all sizes, with no hidden fees or long-term contracts.",
        },
        {
          title: "Customizable Solutions",
          blurb:
            "Tailor your POS with add-ons and integrations to enhance your operations.",
        },
      ],
    };
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>

<style>
.background-image {
  background-image: url("../../public/pos-display.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
