<template>
  <div class="content">
    <div class="column">
      <h3>Sitemap</h3>
      <div class="links">
        <router-link to="/">Home</router-link>
        <router-link to="/about">About</router-link>
        <router-link to="/connect">Get in Touch</router-link>
        <router-link to="/pricing">Packages & Pricing</router-link>
      </div>
    </div>
    <div class="column">
      <h3>Terms of Use</h3>
      <div class="links">
        <router-link to="/privacyPolicy">Privacy Policy</router-link>
        <router-link to="/termsOfService">Terms of service</router-link>
      </div>
    </div>
    <div class="column">
      <h3>Contact</h3>
      <div class="links">
        <p>For questions and enquiries please email <i>sales@beamy.nz</i></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterItem",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 10rem;
  background: var(--overlay-white);
  padding: 1rem;
  .column {
    height: 10rem;
    width: 15rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .links {
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      p {
        margin: 0;
      }
      a {
        width: 10rem;
        text-decoration: none;
        color: var(--text-colour);
        padding: 0.25rem 0.5rem;
        transition: all 500ms ease-in-out;
      } :hover {
        scale: 1.1;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  
}
</style>
